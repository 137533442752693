import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ToastContainer } from 'react-toastify';
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import { useEffect, useState } from "react";
import MobileHome from "./pages/MobileHome";
import Terms from "./pages/terms";
import PrivacyPolicy from "./pages/privacy_policy";
import ChatHistory from "./pages/ChatHistory";
import BingX from './pages/bingx_uid'
import './i18';
import WithdrawBingx from "./pages/withdraw-bingx";
import WithdrawOkx from "./pages/withdraw-okx";
import OkxUid from "./pages/okx_uid";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const availWidth = window.screen.availWidth;
    if (availWidth < 750) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={isMobile ? <MobileHome /> : <Home />} />
        <Route path="/terms/:locales" element={<Terms />} />
        <Route path="/privacy/:locales" element={<PrivacyPolicy />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/chat-history" element={<ChatHistory />} />
        <Route path="/bingx_uid" element={<BingX />} />
        <Route path="/withdraw-bingx" element={<WithdrawBingx />} />
        <Route path="/withdraw-okx" element={<WithdrawOkx />} />
        <Route path="/okx_uid" element={<OkxUid />} />
        <Route path="/hel" element={<a href="/privacy/en">test</a>} />
      </Routes>
      {/* <ToastContainer theme="dark" autoClose={3000} hideProgressBar /> */}
    </Router>
  );
}

export default App;
