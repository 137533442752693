import "./index.css";
const WithdrawOkx = (): JSX.Element => {
  return (
    <>
      <div id="pcontainer">
        <h1>Instruction for Withdrawing to Your OKX account </h1>
        <h1>Through the Web Page:</h1>
        <div className="section">
          <p>
            1. In X AI, on the token swapping page, click on "Register" to
            create your OKX account.{" "}
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/1.png").default}
              alt=""
            />
          </p>
          <p>
            2. Once you're directed to the web page, enter your phone/email to
            sign up.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/2.jpg").default}
              alt=""
            />
          </p>
          <p>
            3. Enter the verification code you received and click on "Sign up
            now" to proceed.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/3.jpg").default}
              alt=""
            />
          </p>
          <p>4. Create your password. When you're done, click on "Next".</p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/4.JPEG").default}
              alt=""
            />
          </p>
          <p>
            5. Once you're directed to the main page, click on your icon in the
            top right corner. Then click on "Verification" to start your
            identity verification process.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/5.jpeg").default}
              alt=""
            />
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/6.jpeg").default}
              alt=""
            />
          </p>
          <p>6. Select "Individual account" and click on "Continue".</p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/7.jpeg").default}
              alt=""
            />
          </p>
          <p>7. Choose your nationality.</p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/8.jpeg").default}
              alt=""
            />
          </p>
          <p>
            8. Fill out your personal info. After you're done, click on "Submit"
            to complete your verification.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/9.jpeg").default}
              alt=""
            />
          </p>
          <p>
            9. Enter your account information once you're directed back to
            X AI.
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/9.png").default}
              alt=""
            />
          </p>
          <p>
            10. Now you can choose the amount of USDT you wish to withdraw and
            make a withdrawal request!
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/10.png").default}
              alt=""
            />
          </p>
          <p>
            11. You're all set! Please wait patiently for your withdrawal to
            reach your OKX account. Don't hesitate to contact us if you have any
            further questions regarding your withdrawal process. Thanks for your
            support for X AI!
          </p>
        </div>
        <br />
        <h1>Identity Verification in the OKX App: </h1>
        <div className="section">
          <p>
            <span>
              Here's how you complete the identity verification in the OKX app：
            </span>
          </p>
          <p>
            1. Download the OKX app and log in to your account. Click on your
            icon in the top left corner. Then click on "Identity verification"
            (as shown in the screenshots below).
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/12.jpeg").default}
              alt=""
            />
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/13.jpeg").default}
              alt=""
            />
          </p>
          <p>2. Click on "Verify" to proceed. </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/14.jpeg").default}
              alt=""
            />
          </p>
          <p>3. Select your nationality. </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/15.jpeg").default}
              alt=""
            />
          </p>
          <p>
            4. Fill out your personal info. After you're done, click on
            "Continue".{" "}
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/16.jpeg").default}
              alt=""
            />
          </p>
          <p>
            5. Now you've completed your verification process! Please don't
            hesitate to contact us if you have any further questions regarding
            your identity verification in OKX. Thanks for your support for
            X AI!
          </p>
          <p>
            <img
              className="okx-img"
              src={require("../../assets/okx/17.jpeg").default}
              alt=""
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default WithdrawOkx;
