/*
 * @Author: ai.zhang
 * @Date: 2023-01-11 18:32:05
 * @LastEditors: weifan
 * @Description:
 */
import { useTranslation } from "react-i18next";
import "./index.scss";
import { LocalesBtn, LocalesText } from "../../components";
import { ANDROID_DOWNLOAD_URL, IOS_DOWNLOAD_URL } from "../../constants";

const Home = (): JSX.Element => {
  const downLoadIOS = () => {
    window.open(
      IOS_DOWNLOAD_URL, "_blank"
    );
  };
  const downLoadGoogle = () => {
    window.open(
      ANDROID_DOWNLOAD_URL,
      "_blank"
    );
  };

  const { t, i18n } = useTranslation();


  return (
    <LocalesText>
      <div className="home">
        <LocalesBtn />
        <div className="main">
          <div className="row_one">
            <img src={require("../../assets/img/row_one.png").default} alt="" />
          </div>

          <div className="row_sec">

            <span>{t('title')}</span>
          </div>
          <div className="row_three">
            <span>{t('desc')}</span>
          </div>


          <div className="row_four">
            <div className="button_chee">{t('word1')}</div>
            <div className="button_chee">{t('word2')}</div>
            <div className="button_chee">{t('word3')}</div>
          </div>

          <div className="row_five">
            <div className="down_btn" onClick={() => downLoadIOS()}>
              <img src={require("../../assets/img/ios.png").default} alt="" />
              &nbsp;
              <span>{t('downloadBtn1')}</span>
            </div>
            <div className="down_btn" onClick={() => downLoadGoogle()}>
              <img
                src={require("../../assets/img/andriod.png").default}
                alt=""
              />
              &nbsp;
              <span>{t('downloadBtn2')}</span>

            </div>
          </div>
        </div>
        <div className="footer">
          <div className="container">
            <div className="left">
              <div className="nav">
                <div className="item">
                  <a
                    // href="https://nyfnxpvxkf.larksuite.com/docx/MJNhd7EIjoKUP5xISM1ukqHtsiZ"
                    href={`/terms/${i18n.language}`}
                    // target={"_blank"}
                    rel="noreferrer"
                  >
                    {t('terms')}
                  </a>
                </div>

                <div className="item">
                  <a
                    // href="https://nyfnxpvxkf.larksuite.com/docx/MJNhd7EIjoKUP5xISM1ukqHtsiZ"
                    // target={"_blank"}
                    href={`/privacy/${i18n.language}`}
                    rel="noreferrer"
                  >
                    {t('privacy')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalesText>
  );
};

export default Home;
