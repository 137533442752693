import { useLocation } from "react-router-dom";

export const useQuery = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryParams: Record<string, any> = {};
    for (let [key, value] of searchParams.entries()) {
        queryParams[key] = value;
    }
    return queryParams;
}