import { FC, useEffect } from "react";
import Img1 from "../../assets/img/withdraw-bingx/en/1.png";
import Img2 from "../../assets/img/withdraw-bingx/en/2.png";
import Img3 from "../../assets/img/withdraw-bingx/en/3.png";
import Img4 from "../../assets/img/withdraw-bingx/en/4.png";
import Img5 from "../../assets/img/withdraw-bingx/en/5.png";
import Img6 from "../../assets/img/withdraw-bingx/en/6.png";
import KrImg1 from "../../assets/img/withdraw-bingx/kr/1.png";
import KrImg2 from "../../assets/img/withdraw-bingx/kr/2.png";
import KrImg3 from "../../assets/img/withdraw-bingx/kr/3.png";
import KrImg4 from "../../assets/img/withdraw-bingx/kr/4.png";
import KrImg5 from "../../assets/img/withdraw-bingx/kr/5.png";
import KrImg6 from "../../assets/img/withdraw-bingx/kr/6.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { I18Enum } from "../../enums";
import "./index.css";

const WithdrawBingx: FC = () => {
  const { t, i18n } = useTranslation();

  const { locales } = useParams();

  const isKR = locales && locales === I18Enum.KR;

  useEffect(() => {
    if (locales) {
      i18n.changeLanguage(locales);
    } else {
      i18n.changeLanguage(I18Enum.EN);
    }
  }, [i18n, locales]);

  return (
    <>
      <div id="pcontainer">
        {/* <h1>Instruction for Withdrawing to Your OKX account </h1>
      <h1>Through the Web Page:</h1> */}
        <div className="section">
          <p>{t("withdraw-bingx.p1")}</p>
          <p>
            <img className="okx-img" src={isKR ? KrImg1 : Img1} alt="" />
          </p>
          <p>{t("withdraw-bingx.p2")}</p>
          <p>
            <img className="okx-img" src={isKR ? KrImg2 : Img2} alt="" />
          </p>
          <p>{t("withdraw-bingx.p3")}</p>
          <p>
            <img className="okx-img" src={isKR ? KrImg3 : Img3} alt="" />
          </p>
          <p> {t("withdraw-bingx.p4")}</p>
          <p>
            <img className="okx-img" src={isKR ? KrImg4 : Img4} alt="" />
          </p>
          <p>{t("withdraw-bingx.p5")}</p>
          <p>
            <img className="okx-img" src={isKR ? KrImg5 : Img5} alt="" />
          </p>
          <p>{t("withdraw-bingx.p6")}</p>
          <p>
            <img className="okx-img" src={isKR ? KrImg6 : Img6} alt="" />
          </p>
          <p>{t("withdraw-bingx.p7")}</p>
        </div>
      </div>
    </>
  );
};

export default WithdrawBingx;
