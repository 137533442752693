import { useTranslation } from "react-i18next";
import "./index.scss";
import { FooterAll, LocalesText } from "../../components";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { I18Enum } from "../../enums";
const Terms = () => {
  const { t, i18n } = useTranslation();
  const { locales } = useParams();

  useEffect(() => {
    // 如果没有传入对应语言的路径则按照浏览器语言来更改语言
    if (!locales) {
      const lan = navigator.language.toLowerCase();
      if (lan.includes("ar")) {
        i18n.changeLanguage(I18Enum.ARABIC);
      } else if (lan.includes("ko")) {
        i18n.changeLanguage(I18Enum.KR);
      } else if (lan.includes("ja")) {
        i18n.changeLanguage(I18Enum.JPN);
      } else {
        i18n.changeLanguage(I18Enum.EN);
      }
    } else {
      i18n.changeLanguage(locales);
    }
  }, [i18n, locales]);

  return (
    <LocalesText>
      <div id="pcontainer">
        <br />
        <h1 className="h0">{t("termsAndServices.section1.title")}</h1>
        <div className="section">
          <p>{t("termsAndServices.section1.p1")}</p>
          <br />
          <p>{t("termsAndServices.section1.p2")}</p>
          <br />
          <p>{t("termsAndServices.section1.p3")}</p>
          <br />
          <p>
            {t("termsAndServices.section1.p4")}
            <br />
            {t("termsAndServices.section1.p5")}
            <br />
            {t("termsAndServices.section1.p6")}
            <br />
            {t("termsAndServices.section1.p7")}
          </p>
          <br />
          <p>
            {t("termsAndServices.section1.p8")}
            <br />
            {t("termsAndServices.section1.p9")}
          </p>
        </div>
        <br />
        <h1>{t("termsAndServices.section4.title")}</h1>
        <div className="section">
          <p>{t("termsAndServices.section4.p1")}</p>
          <br />
          <p>{t("termsAndServices.section4.p2")}</p>
          <br />
          <p>
            {t("termsAndServices.section4.p3")}
            <a href={t("termsAndServices.section4.a1")}>
              {t("termsAndServices.section4.a1")}
            </a>
            {t("termsAndServices.section4.a1End")}
          </p>
          <br />
          <p>{t("termsAndServices.section4.p4")}</p>
          <p>{t("termsAndServices.section4.p5")}</p>
          <br />
          <p>{t("termsAndServices.section4.p6")}</p>
          <br />
          <p>{t("termsAndServices.section4.p7")}</p>
          <p>{t("termsAndServices.section4.p8")}</p>
          <p>{t("termsAndServices.section4.p9")}</p>
          <p>{t("termsAndServices.section4.p10")}</p>
          <br />
          <p>{t("termsAndServices.section4.p11")}</p>
          <br />
          <p>{t("termsAndServices.section4.p12")}</p>
          <p>{t("termsAndServices.section4.p13")}</p>
          <p>{t("termsAndServices.section4.p14")}</p>
          <p>{t("termsAndServices.section4.p15")}</p>
          <p>{t("termsAndServices.section4.p16")}</p>
          <p>{t("termsAndServices.section4.p17")}</p>
        </div>
        <br />
        <h1>{t("termsAndServices.section2.title")}</h1>
        <div className="section">
          <p>{t("termsAndServices.section2.p1")}</p>
        </div>
        <br />
        <h1>{t("termsAndServices.section3.title")}</h1>
        <div className="section">
          <p>{t("termsAndServices.section3.p1")}</p>
        </div>
      </div>
      <FooterAll locales={locales || ''} />
    </LocalesText>
  );
};

export default Terms;
