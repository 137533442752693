import { FC, memo } from 'react';
import './index.css'
// import { useTranslation } from 'react-i18next';

const RulesContent: FC = memo(() => {
  // const { t } = useTranslation();

  return (
    <div className="rules">
        <div className="rules-content">
            <section className="rules-content-section">
                <div className="rules-content-desc">
                    1. Open the BingX app and log into your account. Click on your profile icon in the upper left corner.
                </div>
                <img className="rules-content-img" src="/images/deposit.png" />
            </section> 
            <section className="rules-content-section">
                <div className="rules-content-desc">
                    2. You can see the UID number under your account name. Click on the little square icon next to it to copy it to your clipboard.
                </div>
                <img className="rules-content-img" src="/images/witcoin.png" />
            </section>
            <section className="rules-content-section">
                <div className="rules-content-desc">
                3. Now go back to X AI to paste the UID number you just copied and make a withdrawal request!
                </div>
            </section>
        </div>
    </div>
    
  );
});

export default RulesContent;
