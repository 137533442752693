import "./index.scss";
import Me from "../../assets/img/me.png";
import Ai from "../../assets/img/ai.png";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { ChatHistoryData } from "../../interfaces";
import { getChatHistoryList } from "../../helpers/api";
import { useQuery } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { LocalesText } from "../../components";
import { useTranslation } from "react-i18next";
import { I18Enum } from "../../enums";

const ChatHistory: FC = () => {
  const [chatHistoryList, setChatHistoryList] = useState<ChatHistoryData[]>([]);

  const locales = navigator.language.toLowerCase();

  const { t, i18n } = useTranslation();

  const { chatId, source_type } = useQuery();
  const navigate = useNavigate();

  const initData = useCallback(async () => {
    const dataList = await getChatHistoryList({
      chat_id: chatId,
      source_type,
    });

    if (Array.isArray(dataList)) {
      setChatHistoryList(dataList || []);
    }
  }, [chatId, source_type]);

  const handleClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (locales.includes("ar")) {
      i18n.changeLanguage(I18Enum.ARABIC);
    } else if (locales.includes("ko")) {
      i18n.changeLanguage(I18Enum.KR);
    } else if (locales.includes("ja")) {
      i18n.changeLanguage(I18Enum.JPN);
    } else {
      i18n.changeLanguage(I18Enum.EN);
    }
  }, [locales, i18n]);

  return (
    <LocalesText>
      <div className="chat-history">
        {chatHistoryList?.map((chatHistory, index) => {
          return (
            <Fragment key={index}>
              {chatHistory.question && (
                <div className="chat-history-me">
                  <img className="chat-history-me-icon" src={Me} alt="" />
                  <div className="chat-history-text">
                    {chatHistory.question}
                  </div>
                </div>
              )}
              {chatHistory.answer && (
                <div className="chat-history-ai">
                  <img className="chat-history-me-icon" src={Ai} alt="" />
                  <div className="chat-history-text">{chatHistory.answer}</div>
                </div>
              )}
            </Fragment>
          );
        })}
        <div className="chat-history-btn">
          <div className="chat-history-btn-main" onClick={handleClick}>
            {t("chatHistory.btn")}
          </div>
        </div>
      </div>
    </LocalesText>
  );
};

export default ChatHistory;
