import en from '../i18/en.json';
import ar from '../i18/ara.json';
import { I18Enum } from '../enums';
export const changeHtmlDescription = (language: I18Enum) => {
    let description = ar.seo.share.description;
    if (language !== I18Enum.ARABIC) {
        description = en.seo.share.description;
    }
    document.querySelector('meta[name="description"]')?.setAttribute("content", description);
    document.querySelector('meta[property="og:description"]')?.setAttribute("content", description);
}
