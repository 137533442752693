import en from './en.json';
import ara from './ara.json';
import jpn from './jpn.json';
import kr from './kr.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18Enum } from "../enums";
import { LOCAL_STORAGE_I18 } from "../constants";
import { changeHtmlDescription } from '../utils';

// 国际化相关
const i18Resources = {
    en: {
        translation: en,
    },
    ara: {
        translation: ara,
    },
    jpn: {
        translation: jpn,
    },
    kr: {
        translation: kr,
    },
}

const language = localStorage.getItem(LOCAL_STORAGE_I18);

changeHtmlDescription(language as I18Enum);

i18n.use(initReactI18next).init({
    resources: i18Resources,
    lng: language || I18Enum.ARABIC,
    fallbackLng: language || I18Enum.ARABIC,
    interpolation: {
        escapeValue: false,
    },
});