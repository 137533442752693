import { useTranslation } from "react-i18next";
import "./index.scss";
import { FooterAll, LocalesText } from "../../components";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { I18Enum } from "../../enums";
const PrivacyPolicy = () => {
  const { locales } = useParams();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // 如果没有传入对应语言的路径则按照浏览器语言来更改语言
    if (!locales) {
      const lan = navigator.language.toLowerCase();
      if (lan.includes("ar")) {
        i18n.changeLanguage(I18Enum.ARABIC);
      } else if (lan.includes("ko")) {
        i18n.changeLanguage(I18Enum.KR);
      } else if (lan.includes("ja")) {
        i18n.changeLanguage(I18Enum.JPN);
      } else {
        i18n.changeLanguage(I18Enum.EN);
      }
    } else {
      i18n.changeLanguage(locales);
    }
  }, [i18n, locales]);

  return (
    <LocalesText>
      <div id="pcontainer">
        <br />
        <h1>{t("privacyPolicy.section1.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section1.p1")}</p>
          <br />
          <p>{t("privacyPolicy.section1.p2")}</p>
        </div>
        <br />
        <h1>{t("privacyPolicy.section2.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section2.p1")}</p>
          <br />
          <p>{t("privacyPolicy.section2.p2")}</p>
          <br />
          <p>{t("privacyPolicy.section2.p3")}</p>
          <br />
          <p>
            <ul>
              <li>{t("privacyPolicy.section2.li1")}</li>
              <li>{t("privacyPolicy.section2.li2")}</li>
              <li>{t("privacyPolicy.section2.li3")}</li>
              <li>{t("privacyPolicy.section2.li4")}</li>
              <li>{t("privacyPolicy.section2.li5")}</li>
              <li>{t("privacyPolicy.section2.li6")}</li>
              <li>{t("privacyPolicy.section2.li7")}</li>
            </ul>
          </p>
          <br />
          <p>{t("privacyPolicy.section2.p4")}</p>
          <br />
          <p>{t("privacyPolicy.section2.p5")}</p>
        </div>
        <br />
        <h1>{t("privacyPolicy.section3.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section3.p1")}</p>
        </div>
        <br />
        <h1>{t("privacyPolicy.section4.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section4.p1")}</p>
          <br />
          <p>
            <ul>
              <li>{t("privacyPolicy.section4.li1")}</li>
              <li>{t("privacyPolicy.section4.li2")}</li>
              <li>{t("privacyPolicy.section4.li3")}</li>
              <li>{t("privacyPolicy.section4.li4")}</li>
              <li>{t("privacyPolicy.section4.li5")}</li>
              <li>{t("privacyPolicy.section4.li6")}</li>
              <li>{t("privacyPolicy.section4.li7")}</li>
              <li>{t("privacyPolicy.section4.li8")}</li>
            </ul>
          </p>
        </div>
        <br />
        <h1>{t("privacyPolicy.section5.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section5.p1")}</p>
          <br />
          <p>
            <ul>
              <li>{t("privacyPolicy.section5.li1")}</li>
              <li>{t("privacyPolicy.section5.li2")}</li>
              <li>{t("privacyPolicy.section5.li3")}</li>
              <li>{t("privacyPolicy.section5.li4")}</li>
            </ul>
          </p>
          <br />
          <p>{t("privacyPolicy.section5.p2")}</p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section6.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section6.p1")}</p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section7.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section7.p1")}</p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section8.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section8.p1")}</p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section9.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section9.p1")}</p>
          <br />
          <p>
            <ul>
              <li>{t("privacyPolicy.section9.li1")}</li>
              <li>{t("privacyPolicy.section9.li2")}</li>
              <li>{t("privacyPolicy.section9.li3")}</li>
              <li>{t("privacyPolicy.section9.li4")}</li>
              <li>{t("privacyPolicy.section9.li5")}</li>
            </ul>
          </p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section10.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section10.p1")}</p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section11.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section11.p1")}</p>
        </div>

        <br />
        <h1>{t("privacyPolicy.section12.title")}</h1>
        <div className="section">
          <p>{t("privacyPolicy.section12.p1")}</p>
        </div>

      </div>
      <FooterAll locales={locales || ''} />
    </LocalesText>
  );
};

export default PrivacyPolicy;
