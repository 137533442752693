import { FC, useCallback } from "react";
import "./index.scss";
import { I18Enum } from "../../enums";
import { useTranslation } from "react-i18next";
import cls from "classnames";
import { changeHtmlDescription } from "../../utils";

const LocalesBtn: FC = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguageClick = useCallback(
    (language) => {
      i18n.changeLanguage(language);
      localStorage.setItem("i18", language);
      changeHtmlDescription(language);
    },
    [i18n]
  );

  const handleEnClick = useCallback(() => {
    handleChangeLanguageClick(I18Enum.EN);
  }, [handleChangeLanguageClick]);

  const handleAraClick = useCallback(() => {
    handleChangeLanguageClick(I18Enum.ARABIC);
  }, [handleChangeLanguageClick]);

  const handleKrClick = useCallback(() => {
    handleChangeLanguageClick(I18Enum.KR);
  }, [handleChangeLanguageClick]);

  const handleJpnClick = useCallback(() => {
    handleChangeLanguageClick(I18Enum.JPN);
  }, [handleChangeLanguageClick]);

  const LOCALES_BNT_LIST = [
    {
      type: I18Enum.ARABIC,
      click: handleAraClick,
      text: "عربي",
    },
    {
      type: I18Enum.EN,
      click: handleEnClick,
      text: "English",
    },
    {
      type: I18Enum.KR,
      click: handleKrClick,
      text: "한국어",
    },
    {
      type: I18Enum.JPN,
      click: handleJpnClick,
      text: "日本語",
    },
  ];

  return (
    <div className="locales-btn">
      {LOCALES_BNT_LIST.map((btn, index) => (
        <div
          key={index}
          className={cls("locales-btn-item", {
            "locales-btn-item--active": i18n.language === btn.type,
          })}
          onClick={btn.click}
        >
          {btn.text}
        </div>
      ))}
    </div>
  );
};

export default LocalesBtn;
