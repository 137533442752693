import { FC } from "react";
import './index.scss';
import { I18Enum } from "../../enums";
import { useTranslation } from "react-i18next";
import cls from 'classnames';


const LocalesText: FC = (props) => {

    const { children } = props;

    const { i18n } = useTranslation();

    return (
        <div className={cls('locales-text', {
            'locales-text--ara': i18n.language === I18Enum.ARABIC,
        })}>
            {children}
        </div>
    )
}

export default LocalesText;