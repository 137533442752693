import { FC, useEffect } from "react";
import './index.scss';
import { useTranslation } from "react-i18next";
import { I18Enum } from "../../enums";
import { changeHtmlDescription } from "../../utils";

interface FooterProps {
    locales: I18Enum;
}

const Footer: FC<FooterProps> = (props) => {
    const { locales } = props;
    const { t, } = useTranslation();
    useEffect(() => {
        changeHtmlDescription(locales);
    }, [locales])
    return (
        <div className="footer-all">
            <a href="/">{t('footer.btn1')}</a>
            <a href={`/terms/${locales}`} >{t('footer.btn2')}</a>
            <a href={`/privacy/${locales}`} >{t('footer.btn3')}</a>
        </div>
    )
}

export default Footer;