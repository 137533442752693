/* eslint-disable jsx-a11y/anchor-has-content */
import { useTranslation } from "react-i18next";
import "./index.scss";
import { LocalesBtn, LocalesText } from "../../components";
import { ANDROID_DOWNLOAD_URL, IOS_DOWNLOAD_URL } from "../../constants";

const MobileHome = (): JSX.Element => {
  const userAgent = navigator.userAgent;
  const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const downLoad = () => {
    if (isiOS) {
      window.location.href = IOS_DOWNLOAD_URL;
    } else {
      window.location.href = ANDROID_DOWNLOAD_URL;
    }
  }
  const { t, i18n } = useTranslation();

  return (
    <LocalesText>
      <div
        style={{
          width: "100vw",
          overflowX: "hidden",
        }}
      >
        <LocalesBtn />
        <div className="mobileHome">
          <div className="main">
            <div className="row_one">
              <img src={require("../../assets/img/row_one.png").default} alt="" />
            </div>

            <div className="row_sec">
              <span>{t('title')}</span>
            </div>

            <div className="row_three">
              <span>{t('desc')}</span>
            </div>

            <div className="row_four">
              <div className="button_chee">{t('word1')}</div>
              <div className="button_chee">{t('word2')}</div>
              <div className="button_chee">{t('word3')}</div>
            </div>

            <div className="row_five">
              <div className="down_btn" onClick={() => downLoad()}>
                <span>{t('downloadBtn3')}</span>
                {/* <img src={require("../../assets/img/ios.png").default} alt="" /> */}
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="left">
                <div className="nav">
                  <div className="item">
                    <a
                      href={`/terms/${i18n.language}`}
                      rel="noreferrer"
                    >
                      {t('terms')}
                    </a>
                  </div>

                  <div className="item">
                    <a
                      href={`/privacy/${i18n.language}`}
                      rel="noreferrer"
                    >
                      {t('privacy')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalesText>
  );
};

export default MobileHome;
